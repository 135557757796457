import * as React from "react";
import { Paper } from "@mui/material";
import MRQTab from "./MRQTab";
import BidTab from "./BidTab";
import NavTabs from "../../../../../components/Tabs/NavTabs";
import { MCSContextProvider } from "./MCS.context";
import MasterMaterialTab from "../../../SCMPage/WarehouseTab/MasterMaterialTab";
import DrawingBomTab from "./DrawingBom";
import DrawingRegister from "./DrawingRegisterTab";
import PoReconcile from "./PoReconcile";
import SPIRTab from "./SPIRTab";

export default function MCS3({ project }) {
  const tabs = [
    {
      label: "Drawing BOM",
      panel: <DrawingBomTab />,
    },
    // {
    //   label: "BOM status",
    //   panel: <DrawingRegister />,
    // },
    {
      label: "MRQ",
      panel: <MRQTab />,
    },
    {
      label: "Bid Evaluation",
      panel: <BidTab />,
    },
    {
      label: "Materials Record",
      panel: <MasterMaterialTab />,
    },
    {
      label: "Reconcile",
      panel: <PoReconcile />,
    },
    {
      label: "SPIR",
      panel: <SPIRTab />,
    },
  ];

  return (
    <MCSContextProvider project={project}>
      <Paper sx={{ width: "100%", display: "flex" }}>
        <NavTabs
          tabs={tabs}
          // tabProps={verticalTabStyle}
          navLabel="mcs-tab"
          // vertical={true}
        />
      </Paper>
    </MCSContextProvider>
  );
}
