import {
  Box,
  Collapse,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { materialsCols } from "../../DrawingBom/components/ConsolidateModal/cols";
import SubMaterialTable from "./SubMaterialTable";
import DesignTable from "./DesignTable";

export default function Row({ row }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: 300 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {materialsCols.map((col) => {
          return (
            <TableCell key={col.accessorKey}>{row[col.accessorKey]}</TableCell>
          );
        })}
        <TableCell align="left">{row.calories}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            p: 0,
          }}
          colSpan={16}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                History / Timeline
              </Typography>
              <Grid container>
                <Grid item xs={3} sx={{ pr: 2 }}>
                  <DesignTable />
                </Grid>
                <Grid item xs={9}>
                  <SubMaterialTable />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
