import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MaterialsTable from "./MaterialsTable";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Chip } from "@mui/material";
import { drawingCols } from "./utils";

export default function Row({
  row,
  removeSelectedDrawing,
  selectionMaterials,
  setSelectionMaterials,
}) {
  const [open, setOpen] = React.useState(false);

  const onRemove = () => {
    removeSelectedDrawing(row.id);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: 300 }}>
          <IconButton size="small" sx={{ mr: 2 }} onClick={onRemove}>
            <RemoveCircleOutlineIcon color="error" />
          </IconButton>

          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>

          <Box component="span">
            {selectionMaterials[row.id]?.length ? (
              <Chip
                label={
                  selectionMaterials[row.id]?.length + " materials selected"
                }
                color="primary"
              />
            ) : (
              ""
            )}
          </Box>
        </TableCell>

        {drawingCols.map((col) => {
          return <TableCell key={col.label}>{row[col.key]}</TableCell>;
        })}
        <TableCell align="left">{row.calories}</TableCell>
      </TableRow>
      <TableRow
        sx={{ backgroundColor: "hsla(250, 86%, 97%, 1)", width: "100%" }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                Materials
              </Typography>
              <MaterialsTable drawing={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
