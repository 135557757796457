import React from "react";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";
import DrawingSelection from "../DrawingRegisterTab/components/DrawingSelection";

import { Box, Grid } from "@mui/material";
import TabControl from "./components/TabControl";
import DrawingBomTable from "./components/Table/DrawingBomTable";
import ConsolidateModal from "./components/ConsolidateModal";
import { DrawingBomProvider } from "./DrawingBom.context";

export default function DrawingBomTab() {
  return (
    <DrawingBomProvider>
      <Box>
        <Grid container sx={{ position: "relative" }}>
          <Grid item xs={6}>
            <LabelledBox sx={{ ml: 5 }}>
              <DrawingSelection />
            </LabelledBox>
          </Grid>

          <Grid item xs={6}>
            <LabelledBox sx={{ position: "absolute", right: 40, top: 25 }}>
              <TabControl />

              <ConsolidateModal />
            </LabelledBox>
          </Grid>
        </Grid>

        <Box sx={{ mx: 5 }}>
          <DrawingBomTable />
        </Box>
      </Box>
    </DrawingBomProvider>
  );
}
