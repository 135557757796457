import { Box } from "@mui/material";
import React from "react";
import MrqSelector from "./components/MrqSelector";
import MrqDetails from "./components/MrqDetails";
import MrqMaterialsTable from "./components/MrqMaterialsTable/index";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";
import { MCSContext } from "../MCS.context";
import { getMrqs } from "../api";

const MRQTab = () => {
  const { mrqData, setMrqList } = React.useContext(MCSContext);

  React.useEffect(() => {
    const fetchMrqList = async () => {
      await getMrqs().then((res) => setMrqList(res));
    };

    fetchMrqList();
  }, []);

  return (
    <Box>
      <LabelledBox label="MRQ Manager">
        <MrqSelector />
      </LabelledBox>

      <MrqDetails />

      <MrqMaterialsTable
        data={mrqData?.mrqMaterials || []}
        pos={mrqData?.pos || []}
      />

      {/* <AddDataDialog open={dialog.open} onClose={onClose} /> */}
    </Box>
  );
};

export default MRQTab;
