import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import { lightTable } from "../../../../../../../../styles/muiProps";
import { materialsCols } from "./cols";

export default function MainSelectedMaterialsTable({ consolidatedMaterials }) {
  const table = useMaterialReactTable({
    columns: materialsCols,
    data: consolidatedMaterials,
    ...lightTable,

    muiTableContainerProps: { sx: { maxHeight: "350px" } },
  });

  return <MaterialReactTable table={table} />;
}
