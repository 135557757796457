import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import { Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
// import NewProjectsBox from "./NewProjectsBox";
import ProjectsTable from "./ProjectsTable";
import { getAllProjects } from "./api";
import paths from "../../../../api/paths.api";

const MainProjects = () => {
  const navigate = useNavigate();

  const onCreateNew = () => {
    navigate(paths.projectFormApi);
  };

  const [data, setData] = useState([]);
  const [dataState, setDataState] = useState({
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    const getData = async () => {
      const { data, error } = await getAllProjects();
      if (error) {
        setDataState((prev) => ({ ...prev, isError: true }));
      } else {
        setData(data);
      }
      setDataState((prev) => ({ ...prev, isLoading: false }));
    };
    getData();
  }, []);

  console.log({ dataState });
  return (
    <Box>
      <Box
        sx={{
          pt: 1,
          pl: "4rem",
          pr: "4rem",
          pb: 0,
          minHeight: "95vh",
          background:
            "linear-gradient(90deg, hsla(253, 82%, 98%, 1) 30%, hsla(217, 100%, 93%, 1) 100%);",
        }}
      >
        {/* <Typography variant="h5">Welcome to ChinookDT! </Typography> */}
        <Box
          sx={{
            pt: 3,

            background:
              "linear-gradient(90deg, hsla(253, 82%, 98%, 1) 30%, hsla(217, 100%, 93%, 1) 100%);",
            height: "100%",
          }}
        >
          <Stack direction={"row"} mb={2}>
            <Typography variant="h4" color="darkblue" sx={{ marginBottom: 2 }}>
              Projects
            </Typography>
            {/* <h2>Projects</h2> */}
            <Box sx={{ overflow: "hidden", width: "90%", margin: "auto" }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ color: "#fff", float: "right" }}
                onClick={onCreateNew}
              >
                Create New Project
              </Button>
            </Box>
          </Stack>
          {/* Table */}
          <ProjectsTable data={data && data} state={dataState} />
          <br />
        </Box>
      </Box>
    </Box>
  );
};

export default MainProjects;
