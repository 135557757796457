import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Row from "./Row";
import { Box } from "@mui/material";
import { materialsCols } from "../../DrawingBom/components/ConsolidateModal/cols";

export default function MaterialTable() {
  const materials = [
    {
      description: " PL 2000x6000x8Thk.",
      drawingId: "3949ae3e-8fa9-4390-a3c4-5d4452c01754",
      heatNumber: "",
      id: "2b1cc4c6-4421-4a23-a457-8b4a0030bcdd",
      item: "1",
      markNo: "S1",
      materialCode: "PL000008PBNO",
      materialNo: "SA 516 60",
      mrqNumber: "PL-001",
      mrvNumber: "",
      poNumber: "",
      quantity: "8",
      srvNumber: "",
      tagNumber: "",
      totalWtInKgs: "6028.80",
      wtEachInKgs: "753.60",
    },
    {
      description: " PL 2000x6000x8Thk.",
      drawingId: "3949ae3e-8fa9-4390-a3c4-5d4452c01754",
      heatNumber: "",
      id: "2b1cc4c6-4421-4a23-a457-8b4a0030bcdd",
      item: "1",
      markNo: "S1",
      materialCode: "PL000008PBNO",
      materialNo: "SA 516 60",
      mrqNumber: "PL-001",
      mrvNumber: "",
      poNumber: "",
      quantity: "8",
      srvNumber: "",
      tagNumber: "",
      totalWtInKgs: "6028.80",
      wtEachInKgs: "753.60",
    },
    {
      description: " PL 2000x6000x8Thk.",
      drawingId: "3949ae3e-8fa9-4390-a3c4-5d4452c01754",
      heatNumber: "",
      id: "2b1cc4c6-4421-4a23-a457-8b4a0030bcdd",
      item: "1",
      markNo: "S1",
      materialCode: "PL000008PBNO",
      materialNo: "SA 516 60",
      mrqNumber: "PL-001",
      mrvNumber: "",
      poNumber: "",
      quantity: "8",
      srvNumber: "",
      tagNumber: "",
      totalWtInKgs: "6028.80",
      wtEachInKgs: "753.60",
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {materialsCols.map((col) => {
              return (
                <TableCell
                  key={col.header}
                  sx={{ fontweight: "bold", fontSize: "17px" }}
                >
                  {col.header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {materials.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
