import * as React from "react";
import { Box, Button } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { lightTable } from "../../../../../../../../../styles/muiProps";
import { materialsCols } from "../../ConsolidateModal/cols";
import { DrawingBomContext } from "../../../DrawingBom.context";

export default function MaterialsTable({ drawing }) {
  const [rowSelection, setRowSelection] = React.useState({});

  const { changeSelectionMaterials } = React.useContext(DrawingBomContext);

  // console.log("outside", rowSelection);
  // const onRowSelection = (e) => {
  //   setRowSelection(e);
  //   const res = e();
  //   console.log("inside", res);
  //   const selectedMaterialsIds = Object.keys(rowSelection);

  //   const selectedMaterials = selectedMaterialsIds.map((id) => {
  //     return drawing.materials.find((m) => m.id === id);
  //   });
  //   const drawingSelectedMaterials = {};

  //   drawingSelectedMaterials[drawing.id] = selectedMaterials;

  //   changeSelectionMaterials(drawingSelectedMaterials);
  // };

  const table = useMaterialReactTable({
    data: drawing.materials,
    columns: materialsCols,
    enableRowSelection: true,
    ...lightTable,
    getRowId: (originalRow) => originalRow.id,
    // onRowSelectionChange: onRowSelection,
    // state: { rowSelection },
  });

  console.log("getState", table.getState().rowSelection);

  React.useEffect(() => {
    const selectedMaterialsIds = Object.keys(table.getState().rowSelection);

    const selectedMaterials = selectedMaterialsIds.map((id) => {
      return drawing.materials.find((m) => m.id === id);
    });
    const drawingSelectedMaterials = {};

    drawingSelectedMaterials[drawing.id] = selectedMaterials;

    changeSelectionMaterials(drawingSelectedMaterials);
  }, [table.getState().rowSelection]);

  return (
    <Box sx={{ maxHeight: 400, backgroundColor: "#fff" }}>
      <MaterialReactTable table={table} />
    </Box>
  );
}
