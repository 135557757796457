import { MenuItem } from "@mui/material";
import {
  materialsOptions,
  plateSizeOptions,
  requirementsOptions,
} from "./GenerateTable/utils";

export const groupingTableCols = [
  { accessorKey: "item", header: "Item", size: 50 },
  { accessorKey: "markNo", header: "Mark", size: 50 },
  {
    accessorKey: "quantity",
    header: "Qty",
    enableEditing: false,
    size: 50,
  },
  {
    accessorKey: "totalWtInKgs",
    header: "Total Wt",
    size: 50,
  },
  { accessorKey: "materialNo", header: "Material", size: 70 },
  { accessorKey: "materialCode", header: "Material Code", size: 70 },
];

export const materialsCols = [
  { accessorKey: "item", header: "Item", size: 50 },
  { accessorKey: "markNo", header: "Mark", size: 50 },
  {
    accessorKey: "quantity",
    header: "Qty",
    enableEditing: false,
    size: 50,
  },
  { accessorKey: "totalWtInKgs", header: "Total Wt", size: 50 },
  { accessorKey: "materialNo", header: "Material", size: 70 },
  { accessorKey: "materialCode", header: "Material Code", size: 70 },
  { accessorKey: "description", header: "Description", size: 150 },
  {
    accessorKey: "mrqNumber",
    header: "Mrq #",
    enableEditing: false,
    size: 150,
  },
  {
    accessorKey: "poNumber",
    header: "PO #",
    enableEditing: false,
    size: 150,
  },
  {
    accessorKey: "mrvNumber",
    header: "MRV #",
    enableEditing: false,
    size: 150,
  },
  {
    accessorKey: "srvNumber",
    header: "SRV #",
    enableEditing: false,
    size: 150,
  },
  {
    accessorKey: "heatNumber",
    header: "Heat #",
    enableEditing: false,
    size: 150,
  },

  {
    accessorKey: "actions",
    header: "Actions",
    enableEditing: false,
    size: 90,
  },
];

export const GenerateTableCols = [
  {
    accessorKey: "size",
    header: "Plate Size",
    size: 20,
    muiEditTextFieldProps: ({ row }) => ({
      children: plateSizeOptions
        .find((opt) => opt.value === "16")
        .size.map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: "thickness",
    header: "Thk (mm)",
    // header: (
    //   <div>
    //     <p>Thk</p>
    //     <p>(mm)</p>
    //   </div>
    // ),
    size: 10,
  },
  {
    accessorKey: "materialNo",
    header: "Material",
    size: 50,
    muiEditTextFieldProps: () => ({
      children: materialsOptions
        .map((opt) => opt.material)
        .map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: "requirements",
    header: "Requirements",
    size: 30,
    muiEditTextFieldProps: () => ({
      children: requirementsOptions
        .map((opt) => opt.req)
        .map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: "quantity",
    header: "Qty",
    size: 50,
  },
  {
    accessorKey: "description",
    header: "Description",
    size: 70,
    enableEditing: false,
  },
  {
    accessorKey: "materialCode",
    header: "Material Code",
    size: 40,
    enableEditing: false,
  },
  {
    accessorKey: "totalWeight",
    header: "Total Weight",
    size: 40,
    enableEditing: false,
  },
  { accessorKey: "diff", header: "Diff", size: 50, enableEditing: false },
];
