import { Button, Typography } from "@mui/material";

export const designCols = [
  {
    accessorKey: "drawingBom",
    header: "Drawing Bom (Design)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "drawingNumber",
        header: "Drawing #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "drawingSheet",
        header: "Sheet",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "drawingRev",
        header: "Rev.",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "designQty",
        header: "Consolidated Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],
    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography
          color=""
          sx={{
            pl: 1,
            fontWeight: "bold",
          }}
        >
          Total Qty = 17
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
];
export const SubMaterialsTableCols = [
  {
    accessorKey: "date",
    header: "Date",
    size: 70,
    muiTableHeadCellProps: {
      align: "center",
    },
  },

  {
    accessorKey: "markNo",
    header: "MRQ Bom (Engineering)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "mrqNumber",
        header: "MRQ #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "mrqRev",
        header: "Rev.",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "procureQty",
        header: "Procure Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],

    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography color="" sx={{ pl: 1, fontWeight: "bold" }}>
          Current Procure Qty = 17
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
  {
    accessorKey: "markNo",
    header: "PO Bom (Procurement)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "poNumber",
        header: "PO #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "poRev",
        header: "Rev.",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "poQty",
        header: "PO Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],
    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography
          color=""
          sx={{
            pl: 1,
            fontWeight: "bold",
          }}
        >
          PO Qty = 17
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
  {
    accessorKey: "markNo",
    header: "MRV Bom (Vendor)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "mrvNumber",
        header: "MRV #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "mrvQty",
        header: "MRV Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],

    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography
          color=""
          sx={{
            pl: 1,
            fontWeight: "bold",
          }}
        >
          MRVs Qty = 14
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
  {
    accessorKey: "markNo",
    header: "SRV Bom (Inventory)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "mrvNumber",
        header: "SRV #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "mrvQty",
        header: "SRV Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],

    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography
          color=""
          sx={{
            pl: 1,
            fontWeight: "bold",
          }}
        >
          SRVs Qty = 14
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
  {
    accessorKey: "Status",
    header: "Status",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "remainingNumber",
        header: "Remaining",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "btn",
        header: "Reconcile",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          console.log({ row });
          return (
            row.original.remainingNumber && (
              <Button variant="outlined">Reconcile</Button>
            )
          );
        },
      },
    ],
    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography color="warning.main" sx={{ pl: 1, fontWeight: "bold" }}>
          Remaining : - 3
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
];
